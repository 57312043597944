import Cropper from 'cropperjs';
import { useState, useRef, useEffect } from 'preact/hooks';
import { html } from 'htm/preact';

import { renderComponent, tie } from '@app/dom';
import { Modal, useModal } from '@app/widgets/components/Modal';
import { i18n } from '@app/i18n';

const AvatarCropModal = ({ parentElement }) => {
  const previewRef = useRef();
  const [cropper, setCropper] = useState(null);
  const [isOpen, setIsOpen, triggerData] = useModal(parentElement);
  const onCloseButtonClick = () => setIsOpen(false);
  const onSave = () => {
    if (!cropper) {
      return;
    }

    const canvas = cropper.getCroppedCanvas({
      width: 256,
      height: 256,
    });

    canvas.toBlob((blob) => {
      var formData = new FormData();

      formData.append('authenticity_token', triggerData.authenticityToken);
      formData.append('upload[file]', blob, 'avatar.png');

      fetch(triggerData.actionUrl, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (!result.path) {
            return;
          }

          const destinationId = triggerData.avatarCrop;
          const destinationElement = document.getElementById(destinationId);
          const previewId = triggerData.avatarCropPreview;
          const previewElement = document.getElementById(previewId);

          destinationElement.value = result.path;
          previewElement.setAttribute('src', result.path);

          setIsOpen(false);
        });
    });
  };

  useEffect(() => {
    if (isOpen) {
      setCropper(
        new Cropper(previewRef.current, {
          aspectRatio: 1,
          viewMode: 3,
        }),
      );
    } else if (cropper) {
      cropper.destroy();
      setCropper(null);
    }
  }, [isOpen]);

  return html`<${Modal} isOpen=${isOpen}>
      <div className="o-layout o-layout--align-center o-layout--space-between u-padding-vertical--s u-padding-horizontal--xl">
        <div className="o-layout__item">
          <h3 className="c-typography c-typography--header-3 u-text--align-center">${i18n.t(
            'worker.profile.upload_picture',
          )}</h3>
        </div>
        <div className="o-layout__item">
          <div className="o-layout o-layout--align-center o-layout--justify-center">
            <div className="o-layout__item u-padding-right--m">
              <button onClick=${onCloseButtonClick} className="c-button c-button--secondary">${i18n.t(
                'common.cancel',
              )}</button>
            </div>
            <div className="o-layout__item">
              <button onClick=${onSave} className="c-button c-button--primary">${i18n.t('common.save')}</button>
            </div>
          </div>
        </div>
      </div>
      <div className="u-padding-top--m u-padding-bottom--l u-padding-horizontal--xl">
        <img ref=${previewRef} src=${triggerData.previewUrl} height="480" />
      </div>
  </${Modal}>`;
};

tie.bind('input[data-avatar-crop]', (input) => {
  input.addEventListener('change', (event) => {
    const init = (url) => {
      input.value = '';

      const event = new CustomEvent('temply.showModal', {
        bubbles: true,
        detail: {
          id: 'avatar-crop-modal',
          data: {
            previewUrl: url,
            authenticityToken: input.form.elements.authenticity_token.value,
            actionUrl: input.form.action,
            ...input.dataset,
          },
        },
      });

      input.dispatchEvent(event);
    };

    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      if ('URL' in window) {
        init(URL.createObjectURL(file));
      } else if ('FileReader' in window) {
        const reader = new FileReader();
        reader.onload = function () {
          init(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  });
});

renderComponent('#avatar-crop-modal', AvatarCropModal);
